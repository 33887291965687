import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';

export const Footer = () => {
    return(
        <Container>
            <Row className='footer'>
                <p>made by me in 2024</p>
            </Row>
        </Container>
    )
}